import * as React from 'react'
import { Icon, Collapse, ChakraProvider, Text, Button, Flex, extendTheme, Heading, Center, Box, Container, Divider, Grid, Spacer, HStack, List, ListItem, ListIcon, AspectRatio } from '@chakra-ui/react'
import { MdSettings, MdPhone, MdCheckCircle, MdDoNotDisturbOn, MdNotificationsOff } from "react-icons/md"
import { FloatingMenu, MainButton } from 'react-floating-button-menu';

function GeneralInfo() {
  const [show, setShow] = React.useState(false)

  const handleToggle = () => setShow(!show)
  
      return (
        <>
        <Flex>
        <Spacer />
        <Box w={[400, 1024]}>
        <Grid w="100%" templateColumns="repeat(4, 1fr)" align="center" mt={5} mb={5}>
          <Box w="100%">
            <Text fontSize="5xl"><b>6</b></Text>
            <Text>Guests</Text>
            </Box>
          <Box w="100%">
          <Text fontSize="5xl"><b>2</b></Text>
            <Text>Bedrooms</Text>
            </Box>
          <Box w="100%">
          <Text fontSize="5xl"><b>1</b></Text>
            <Text>Bathroom</Text>
            </Box>
          <Box w="100%">
          <Text fontSize="5xl"><b>3</b></Text>
            <Text>Beds</Text>
            </Box>
        </Grid>
        <Divider />
        <Text fontSize="5xl" align="center" mt={5}>
        <b>Rooms</b>
        </Text>
          <Flex align="center" mb={5}>
            <Spacer />
            <Box p="5" m={5} borderWidth="1px" width="250px" borderRadius="lg">
              <Text><b>Room 1</b></Text>
              <Text>1 double bed, 1 sofa bed</Text>
            </Box>
            <Spacer />
            <Box p="5" m={5} borderWidth="1px" width="250px" borderRadius="lg">
              <Text><b>Room 2</b></Text>
              <Text>1 queen bed</Text>
            </Box>
            <Spacer />
          </Flex>
          <Divider />
          <Text fontSize="5xl" align="center" mt={5}>
        <b>Descriptions</b>
        </Text>
        <Collapse startingHeight={120} in={show}>
        Welcome to our recently redecorated and carefully designed home away from home!
<br/>
<br/>
Our rental is a fully legal and licensed vacation home, it meets all the strict Niagara Falls safety requirements, so you can relax and rest easy.
We have just hired a professional interior decorator to totally redesign our space to meet our high standards and our guests modern sensibilities.
Our unit sleeps 6 people, and because the downstairs unit is currently closed you will be the only people in the house.
<br/>
<br/>
<b>The space</b>
<br/>
We care about your comfort and safety and strive to be the best that we can be. Some features of our listing are:
<br/>
- Easy 24 hour self check in<br/>
- Outdoor surveillance to make our guests feel safe<br/>
- Professionally, and very thoroughly cleaned after every guest<br/>
- 5 minute drive from the falls.<br/>
- Wired in Carbon monoxide and smoke detectors<br/>
- First aid kit, and if it should be necessary the unit is only a 2-minute drive from the hospital.
<br/><br/>
This rental is for an upstairs apartment consisting of one bedroom containing a den, a second bedroom, a full bathroom and a fully supplied kitchen.
<br/><br/>
The master bedroom and den combination consist of a full-sized bed and a full-sized pull-out couch. The second bedroom contains a queen bed. Each room contains a large closet for your convenience.
<br/><br/>
The design of the master bedroom and den is a modern twist on a farmhouse style and is decorated in a soothing neutral palette perfect for relaxing.
<br/><br/>
The second bedroom containing one queen bed utilizes a rich color palette of deep blues and gold that combine with the wooden floor to create a sophisticated atmosphere, but the koala portrait taking center stage above the bed keeps the space from taking itself too seriously and adds a little playful touch.
<br/><br/>
The kitchen is fully equipped to meet all your needs, with a 4 burner electric stove and oven, a microwave, full size refrigerator, coffee pot and kettle. You will also find cutting boards, pots and pans, utensils, cooking basics such as salt, pepper, sugar, flour, common spices and oil. Our kitchen also contains a well stocked coffee and tea selection, with many of the most commonly drunk teas both in north America and abroad, as well as coffee whitener and sugar alternatives, all available to ensure that you can maintain your favorite morning rituals, even when away from home.
<br/><br/>
The apartment contains one full bathroom with a shower/bath combo and a luxurious waterfall sink situated under an over sized mirror. The bathroom has been totally updated with modern gold fixtures and a light modern color palette to create a relaxing and airy atmosphere.
<br/><br/>
For our guests with children: Welcome! We offer a pack n play crib/ play yard, a high chair and a stroller
for day trips. We also keep outlet safety plugs available for our guests to use as necessary.
<br/><br/>
Our rental is very near to many activities and necessities in the Niagara Falls area:<br/>
- 5 minute drive to Niagara Falls<br/>
- 5 minute drive to Canadian border (which is currently closed to all non citizens/permanent
residents)<br/>
- 5 minute walk to the nearest grocery store<br/>
- 35 minute walk to Seneca Casino<br/>
- 20 minute walk to Metro Transportation center<br/>
- 15 minute drive to Niagara Falls airport<br/>
- 40 minute drive to Buffalo airport
<br/><br/>
If you have any questions about our listing, or want to book, please don’t hesitate to contact me!<br/>
We love our redecorated apartment unit and we know that you will too.<br/>
<br/>
<b>Guest access</b><br/>
We provide free parking in the back yard. You can find a Google map with a red arrow for instruction.
<br/>
We are located in Little Italy, with lots of good restaurants nearby. Please check our guestbook for more details. We are a 5 minute drive to the Falls.
<br/><br/>
<b>Other things to note</b><br/>
***Covid- 19 info***<br/>
For anyone with questions about covid-19 as it regards to your stay at our rental: We have intensified our cleaning routine, we now disinfect every touchable surface in our unit, including but not limited to light switches and pull chains, buttons on the TV, door knobs, every touchable surface etc. We are doing everything in our power to ensure our unit is completely clean and safe for our guests. If you have any questions or concerns please let us know and we will do our best to address them or send you to someone who can.
<br/><br/>

We have classified our listing as appropriate for babies and children, not because we have baby proofed every inch of it but because we offer amenities for families that we believe make it appropriate for families with children. However the unit is not fully baby proofed and safe for babies and toddlers to run free in.
        </Collapse>
        <Button size="sm" onClick={handleToggle} mt="1rem" mb={5}>
        Show {show ? "Less" : "More"}
      </Button>
        
        <Divider />
          <Text fontSize="5xl" align="center" mt={5}>
        <b>Amenities</b>
        </Text>
        <Flex align="top" mb={5}>
            <Box p="5" m={5} borderWidth="1px" width="150px" borderRadius="lg">
              <Text mb={3}><b>Overall</b></Text>
              <List spacing={3}>
              <ListItem>
                
    <ListIcon as={MdCheckCircle} color="green.500" />
    Wifi
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Central air conditioning
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Heating
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Patio
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Backyard
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Free parking on backyard or street parking
  </ListItem>
              </List>
            </Box>
            <Box p="5" m={5} borderWidth="1px" width="150px" borderRadius="lg">
            <Text mb={3}><b>Bath</b></Text>
              <List spacing={3}>
              <ListItem>
                
    <ListIcon as={MdCheckCircle} color="green.500" />
    Bathtub
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Hair dryer
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Cleaning products
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Shampoo, Conditioner, Body soap, Shower gel
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Hot water
  </ListItem>
              </List>
            </Box>

            <Box p="5" m={5} borderWidth="1px" width="150px" borderRadius="lg">
            <Text mb={3}><b>Living</b></Text>
              <List spacing={3}>
              <ListItem>
                
    <ListIcon as={MdCheckCircle} color="green.500" />
    Pack ’n Play/travel crib
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    TV
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    High chair
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Board games
  </ListItem>
              </List>
            </Box>

            <Box p="5" m={5} borderWidth="1px" width="150px" borderRadius="lg">
            <Text mb={3}><b>Bed</b></Text>
              <List spacing={3}>
              <ListItem>
                
    <ListIcon as={MdCheckCircle} color="green.500" />
    Towels, bed sheets, soap and toilet paper
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Hangers
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Bed linens
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Extra pillows and blankets
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Clothing storage
  </ListItem>
              </List>
            </Box>

            <Box p="5" m={5} borderWidth="1px" width="150px" borderRadius="lg">
            <Text mb={3}><b>Kitchen</b></Text>
              <List spacing={3}>
              <ListItem>
                
    <ListIcon as={MdCheckCircle} color="green.500" />
    Refridgerator
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Microwave
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Pots and pans, oil, salt and pepper
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Dishes and silverware
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Stove
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Oven
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Hot water kettle
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Coffee maker
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Wine glasses
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Toaster
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Baking sheet
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Coffee
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Dining table
  </ListItem>
              </List>
            </Box>

          </Flex>
          <Divider />
          <AspectRatio ratio={16 / 9} m={5}>
  <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2913.3973389490866!2d-79.04021078423521!3d43.09616399628845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d342becbf74eeb%3A0x53789b2a1c905462!2s734%2020th%20St%2C%20Niagara%20Falls%2C%20NY%2014301%2C%20USA!5e0!3m2!1sen!2sca!4v1621662689274!5m2!1sen!2sca"
    alt="Chic n' Cozy at Niagara Falls"
  />
</AspectRatio>

<Divider />
          <Text fontSize="5xl" align="center" mt={5}>
        <b>House rules</b>
        </Text>
        <List spacing={3} mb={5}>
              <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Check-in: After 4:00 pm
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Check-out: 11:00 am
  </ListItem>
  <ListItem>
    <ListIcon as={MdCheckCircle} color="green.500" />
    Self check-in with smart lock
  </ListItem>
  <ListItem>
    <ListIcon as={MdDoNotDisturbOn} color="red.500" />
    No smoking
  </ListItem>
  <ListItem>
    <ListIcon as={MdDoNotDisturbOn} color="red.500" />
    No pets
  </ListItem>
  <ListItem>
    <ListIcon as={MdDoNotDisturbOn} color="red.500" />
    No cutting directly on counter top
  </ListItem>
  <ListItem>
    <ListIcon as={MdDoNotDisturbOn} color="red.500" />
    No abrasive materials directly on counter top
  </ListItem>
  <ListItem>
    <ListIcon as={MdDoNotDisturbOn} color="red.500" />
    No shoes in the apartment
  </ListItem>
  <ListItem>
    <ListIcon as={MdDoNotDisturbOn} color="red.500" />
    No unregistered guests (if you plan on inviting someone whose not staying at the apartment just notify me)
  </ListItem>
  <ListItem>
    <ListIcon as={MdDoNotDisturbOn} color="red.500" />
    Please don’t leave food behind
  </ListItem>
  <ListItem>
    <ListIcon as={MdNotificationsOff} color="blue.500" />
    Quiet hours after 10pm
  </ListItem>
              </List>





        </Box>


        <Spacer />

        </Flex>

        </>
      );
}

export default GeneralInfo
import React, { useState } from "react"
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { ChakraProvider, Text, Button, Flex, extendTheme, Heading, Center, Box, Container, Grid } from '@chakra-ui/react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import StyledBackgroundSection from '../components/BackgroundSection'
import DateRangePickerWrapper from '../components/DateRangePickerWrapper';
import SlideShow from '../components/Slideshow'
import GeneralInfo from '../components/GeneralInfo'

const theme = extendTheme({
  fonts: {
    body: 'Source Sans Pro',
    heading: 'Source Sans Pro',
    mono: 'Menlo, monospace'
  }
})

function IndexPage() {
  const [startDate, setStartdate] = useState("")
  const [endDate, setEnddate] = useState("")
  const [bookingLink, setBookingLink] = useState("/booking")

  function handleDateChange(start, end) {
    var startString, endString
    if (start) {
      startString = start.format("YYYY-MM-DD")
      setStartdate(startString)
    }
    if (end) {
      endString = end.format("YYYY-MM-DD")
      setEnddate(endString)
    }
    if (start && end) {
      setBookingLink("/booking?start=" + startString + "&end=" + endString)
    }
  }

  return (
  <ChakraProvider theme={theme}>
    <Layout>
      <Seo title='Home' />
      <StyledBackgroundSection titleName='Niagara Falls'>
        <Flex
          height='100vh'
          alignItems='center'
          justifyContent='center'
          direction='column'
          color='white'
        >
          <Heading mb="4">
            <Text fontSize="5.25rem" line-height="1em">
              Chic n' Cozy
            </Text>
          </Heading>
          <Text mb="10">
            Your vacation home at Niagara Falls. <strong>Book Now!</strong>
          </Text>
          <Center mb="6"><DateRangePickerWrapper startDateId="startDate" endDateId="endDate" onDatesChange={handleDateChange}/></Center>
          <Link to={bookingLink}>
          <Button
            variant='outline'
            size='lg'
            height='48px'
            width='200px'
            borderRadius='20px'
          >
            <Text fontSize="1rem">Check Availability</Text>
          </Button>
          </Link>
        </Flex>
      </StyledBackgroundSection>

      <Flex height='100vh'>
      <SlideShow />
      </Flex>


        <GeneralInfo width="100%"/>

    </Layout>
  </ChakraProvider>
  )
}

export default IndexPage

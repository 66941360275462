import * as React from 'react'
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

/* https://reactjsexample.com/dependency-free-react-carousel-component-with-support-for-lazy-loading/ */
const SlideShow = () => {
    const images = [...Array(32).keys()].map((key) => ({
        src: `../images/room/${key + 1}.jpeg`
      }));
    
      return (
        <Carousel images={images} objectFit='contain'/>
      );
}

export default SlideShow